export const place = [
  '阳台',
  '厨房的桌子',
  '浴室',
  '卧室',
  '客厅',
  '天台',
  '安全通道',
  '走廊上',
  '公园',
  '在沙发上',
  '衣柜里',
  '后院',
  '窗前',
  '镜子前',
  '汽车里',
  '汽车引擎盖',
  '办公室',
  '在帐篷里',
  '电梯里',
  '屋顶',
  '游泳池',
  '海里',
  '沙滩',
  '瀑布下',
  '好朋友家中',
  '电影院里',
  '公共厕所中',
  '飞机上',
  '健身房',
  '乡村散步',
  '谷仓里',
  '水床上',
  '骑摩托车',
  '干草堆中',
  '毛皮地毯上',
  '在船上',
]
