import { useState } from 'react'
import './index.scss'
import { images } from './asset/body/index'
import { place } from './data'

function Kama() {
  const [index, setIndex] = useState(
    Math.round(Math.random() * images.length - 1)
  )
  const [p, setP] = useState(Math.round(Math.random() * place.length - 1))
  const handleChange = () => {
    setIndex(Math.ceil(Math.random() * images.length - 1))
    setP(Math.ceil(Math.random() * place.length - 1))
  }
  return (
    <div className="kama">
      <header>
        <p>
          <span>时间: </span>任何时候
        </p>
        <p>
          <span>地点: </span>
          {place[p]}
        </p>
      </header>
      <section className="container">
        <div
          className="body"
          style={{ backgroundImage: `url(${images[index]})` }}
        />
      </section>
      <footer className="footer">
        <div className="change" onClick={handleChange}>
          难度太高? 换一个
        </div>
      </footer>
    </div>
  )
}

export default Kama
