import "./App.css";

function App() {
  return (
    <div className="App">
      <div>这是一盘知识的黄鱼！</div>
      <a href="mailto:laoda@xx666xx.xyz">给wo发送邮件</a>
    </div>
  );
}

export default App;
