import { useState } from "react";
import * as hp from "../../util/index";
import "./index.scss";

function Whipser() {
  const [list, setList] = useState([]);
  // 生成链接
  const [text, setText] = useState("");
  const genLink = async () => {
    // const formdata = new FormData();
    // formdata.append("text", text);
    const req = { text };
    const json = await hp.post("/addWord", req);
    console.log("json", json);
  };
  const listNode = list.map((item) => {
    const { id, text, status } = item;
    return (
      <div key={id} className="word">
        <div className="word-name">内容：{text}</div>
        <div className="word-type">状态：{status}</div>
        <button>复制链接</button>
      </div>
    );
  });
  return (
    <>
      <div>
        <input
          type="text"
          onInput={(event) => setText((event.target as HTMLInputElement).value)}
        />
        <button onClick={genLink}>生成</button>
      </div>
      {listNode}
    </>
  );
}
export default Whipser;
