const baseUrl = "https://fancy-union-d27c.loveus.workers.dev";
// const baseUrl = 'http://localhost:3000/api'
const reg = new RegExp("^(http|https)://"); // 检测http or https

export async function get(path: string, pramars = {}, headers = {}) {
  let url = `${baseUrl}${path}`;
  if (reg.test(path)) {
    url = path;
  }
  // 参数
  if (Object.keys(pramars).length > 0) {
    // url += `?${querystring.stringify(pramars)}`
  }
  const response = fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    // credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow",
    referrer: "no-referrer", // no-referrer, *client
  });
  return response;
}
export async function post(path: string, pramars: any, headers = {}) {
  let url = `${baseUrl}${path}`;
  if (reg.test(path)) {
    url = path;
  }
  // 头部
  const headersConfig = new Headers({
    "Content-Type": "application/json",
    ...headers,
  });
  // 参数
  if (Object.prototype.toString.call(pramars) === "[object FormData]") {
    // 如果设置是multipart/form-data，则没法自动设置边界（boundary）
    headersConfig.delete("Content-Type");
  }
  if (headersConfig.get("Content-Type") === "application/json") {
    pramars = JSON.stringify(pramars);
  }
  const response = await fetch(url, {
    method: "post",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: headersConfig,
    // redirect: "follow",
    // referrer: "no-referrer",
    body: pramars,
  });
  return response.json();
}
// 这里put一定要指明Content-type，
// 因为firebase那边的上传签名是包含这个的
export function put(path: string, pramars: any, headers = {}) {
  let url = `${baseUrl}${path}`;
  if (reg.test(path)) {
    url = path;
  }
  return fetch(url, {
    method: "put",
    headers: {
      "Content-Type": pramars.type,
    },
    body: pramars,
  });
}

// export default { get, post, put };
